import React, { useContext, useState, useEffect } from "react";
import useStore from "../../../context/useStore";
import { authActions } from "../../../context/actions/authActions";
import ForgotPassword from "./ForgotPassword";
import Control from "../../common/Controls/Control";
import SaveBtn from "../../common/SaveBtn";
import tools from "../../../helpers/tools";
import LoginErrorBox from "./LoginErrorBox";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

var timerInterval = null;
var recaptcha;

const Login = () => {
  const [state, dispatch] = useStore();
  var actions = authActions(state, dispatch);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPassword, setForgotPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(0);
  const [captchaToken, setCaptchaToken] = useState(false);

  const [canLoggin, setCanLoggin] = useState(true);

  useEffect(() => {
    document.querySelector(".login-form").scrollIntoView();
  }, []);

  useEffect(() => {
    if (Object.values(state.errors).length > 0) {
      setIsLoading(false);
      if (state.errors.password) {
        setPassword("");
      }
      if (state.errors.timer) {
        clearInterval(timerInterval);
        setTimer(parseInt(state.errors.timer));
      }
    }
  }, [state.errors]);

  useEffect(() => {
    setTimer(false);
    setCanLoggin(true);
    setCaptchaToken(false);
    //updateToken();
  }, [forgotPassword]);

  function loginHandle(e) {
    e.preventDefault();

    setIsLoading(true);
  }

  useEffect(() => {
    if (timer > 0) {
      setCanLoggin(false);
    }
  }, [timer]);

  function timerFinish() {
    setCanLoggin(true);
  }

  const verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    setIsLoading(false);
    if (!forgotPassword) {
      actions.loginUser({ login, password, captchaToken: recaptchaToken });
    }
    setCaptchaToken(recaptchaToken);
  };

  const updateToken = () => {
    // you will get a new token in verifyCallback
    if (recaptcha) {
      recaptcha.execute();
    }
  };

  const handleForgotPasswordClick = (isTrue) => {
    setIsLoading(isTrue);
    setForgotPassword(isTrue);
  };

  return (
    <div className="shadow px-5 " style={{ paddingBottom: 100 }}>
      <div className="row ">
        <div className="col-12 ">
          {forgotPassword ? (
            <ForgotPassword
              handleForgotPasswordClick={handleForgotPasswordClick}
              captchaToken={captchaToken}
            />
          ) : (
            <form
              onSubmit={loginHandle}
              className="d-flex align-items-center justify-content-start flex-column mx-auto animated fadeIn login-form "
              style={{ maxWidth: 500 }}
            >
              <div className=" text-center">
                <h1 className="my-5 pt-5  text-secondary main-title">
                  CONNEXION
                  <hr className="w-50 mt-4" />
                </h1>
              </div>
              <Control
                className="w-100"
                autoFocus={true}
                label="Email"
                name="login"
                id="login"
                type="email"
                value={login}
                change={(e) => setLogin(e.target.value)}
                error={state.errors}
                isValid={tools.validateEmail(login)}
                inputStyle={{
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              />

              <Control
                className="w-100"
                label="Mot de passe"
                name="password"
                id="password"
                type="password"
                value={password}
                change={(e) => setPassword(e.target.value)}
                error={state.errors}
                isValid={password.length > 6}
                showValidation={false}
                inputStyle={{
                  borderTopRightRadius: 5,
                  borderBottomRightRadius: 5,
                }}
              />

              <LoginErrorBox timer={timer} timerFinish={timerFinish} canLoggin={canLoggin} />
              <div className="w-100 mx-auto d-flex justify-content-between">
                <button
                  type="button"
                  style={{ fontSize: 12 }}
                  onClick={() => handleForgotPasswordClick(true)}
                  className="btn btn-link p-0"
                >
                  mot de passe oublié ?
                </button>

                {canLoggin && (
                  <SaveBtn
                    save={loginHandle}
                    className="animated zoomIn"
                    isSaving={isLoading}
                    text="Je me connecte"
                    type="primary"
                    typeBtn="submit"
                  />
                )}
              </div>
            </form>
          )}
        </div>

        {isLoading && (
          <GoogleReCaptcha onVerify={verifyCallback} />
        )}
      </div>
      <div
        className="mt-4 text-center px-3"
        style={{
          fontSize: 9,
          right: 0,
        }}
      >
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy">Privacy Policy</a> and{" "}
        <a href="https://policies.google.com/terms">Terms of Service</a> apply.
      </div>
    </div>
  );
};

export default Login;
